import api from "@/base/utils/request";

// 获取来源列表
export const sourceList = data => {
    return api({
        url: "/admin/portal/source/index",
        method: "post",
        data
    });
};
// 获取来源tabs
export const sourceTabs = data => {
    return api({
        url: "/admin/portal/source/tabs",
        method: "post",
        data
    });
};
//更新显示状态
export const updateStatus = data => {
    return api({
        url: "/admin/portal/source/updateStatus",
        method: "post",
        data
    });
};
//新增和编辑
export const addSource = data => {
    return api({
        url: "/admin/portal/source/save",
        method: "post",
        data
    });
};
//来源详情
export const sourceDetail = data => {
    return api({
        url: "/admin/portal/source/detail",
        method: "post",
        data
    });
};
//还原
export const putBackSource = data => {
    return api({
        url: "/admin/portal/source/putBack",
        method: "post",
        data
    });
};
//软删除
export const softDeleteSource = data => {
    return api({
        url: "/admin/portal/source/softDelete",
        method: "post",
        data
    });
};
//硬删除
export const delSource = data => {
    return api({
        url: "/admin/portal/source/del",
        method: "post",
        data
    });
};
//排序
export const updateSort = data => {
    return api({
        url: "/admin/portal/source/updateSort",
        method: "post",
        data
    });
};

export const getAllSources = (data) => {
    return api({url:'/admin/portal/source/all', method: 'post', data})
}

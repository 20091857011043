<template>
  <div class="box-list" :class="[useHover?'use-hover':'']">
    <div class="list-colunm">
      {{this.value | placeholder}}
    </div>
    <el-popover placement="bottom" width="300" trigger="click" v-model="visible">
      <div class="input-popover">
        <p>{{title}}</p>
        <el-form :model="form" :rules="rules" ref="popover" @submit.native.prevent>
          <el-form-item v-if="type === 'number'" prop="inputNumber">
            <el-input type="number" ref="listInput" v-model.number="form.inputNumber" @keyup.enter.native="submit" clearable></el-input>
          </el-form-item>
          <el-form-item v-if="type === 'string'" prop="inputString">
            <el-input type="text" ref="listInput" v-model="form.inputString"  @keyup.enter.native="submit" clearable></el-input>
          </el-form-item>
        </el-form>
        <div>
          <el-button size="small" @click="visible = false">取消</el-button>
          <el-button :loading="buttonLoading" size="small" type="primary" @click="handleSave">保存</el-button>
        </div>
      </div>
      <span class="icon el-icon-edit" ref="icon" slot="reference"></span>
    </el-popover>
  </div>
</template>

<script>
export default {
  model: {
    prop: "value",
    event: "updateValue",
  },
  props: {
    title: {
      type: String,
      default: "重新编辑",
    },
    type: {
      type: String,
      default: "number",
    },
    value: {
      type: [String, Number],
    },
    integer: {
      type: Boolean,
      default: false,
    },
    min: {
      type: Number,
      default: 0,
    },
    useHover: {
      type: Boolean,
      default: true
    },
    buttonLoading: Boolean
  },
  data() {
    return {
      form: {
        inputNumber: 0,
        inputString: "",
      },
      rules: {
        inputNumber: [
          { required: true, message: "数据不能为空", trigger: "blur" },
          { validator: this.limitPass, trigger: "blur" },
        ],
        inputString: [
          { required: true, message: "数据不能为空", trigger: "blur" },
        ],
      },
      visible: false,
    };
  },

  methods: {
    //验证数字限制条件
    limitPass(rule, value, callback) {
      if (value >= this.min) {
        if (this.integer && !Number.isInteger(value)) {
          callback(new Error("必须是整数!"));
        } else {
          callback();
        }
      } else {
        callback(new Error(`不能小于${this.min}`));
      }
    },

    submit(e){
      this.handleSave()
    },

    handleSave() {
      this.$refs.popover.validate((valid) => {
        if (valid) {
          if (this.type === "number") {
            this.$emit("updateValue", this.form.inputNumber);
            this.$emit("change");
          }
          if (this.type === "string") {
            this.$emit("updateValue", this.form.inputString);
            this.$emit("change");
          }
          this.$message.success("操作成功");
          this.visible = false;
        } else {
        }
      });
    },
  },
  created() {

    if (this.type === "number") {
      this.form.inputNumber = this.value;
    }
    if (this.type === "string") {
      this.form.inputString = this.value;
    }
  },
  watch: {
    value(val) {
      if (this.type === "number") {
        this.form.inputNumber = val;
      }
      if (this.type === "string") {
        this.form.inputString = val;
      }
    },
    visible(val) {
      if (!val) {
        this.$nextTick(() => {
          this.$refs.icon.style.display = "none";
        });
      } else {
        this.$nextTick(() => {
          this.$refs.icon.style.display = "inline-block";
          this.$refs.listInput.$refs.input.focus();
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.box-list {
  display: flex;

  .icon {
    font-size: 16px;
    margin-left: 6px;
    color: #3576ff;
    cursor: pointer;
    display: none;
  }

  &.use-hover:hover {
    .icon {
      display: inline-block !important;
    }
  }

  &:not(.use-hover) {
    .icon {
      display: inline-block;
    }
  }
}
.list-colunm {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.input-popover {
  text-align: center;
  padding: 7px 10px 10px;
  p {
    margin-bottom: 15px;
    font-size: 15px;
    font-weight: bold;
  }
  .el-button {
    width: 70px;
    height: 30px;
    border-radius: 15px;
  }
}
</style>

<template>
  <div class="list-page">
    <div class="top-btn" v-if="filterForm.tab == 'normal'">
      <el-button size="small" type="primary" @click="handleShowAdd"
        >添加</el-button
      >
    </div>
    <SourceScreen :filterForm="filterForm" :uploadFilter="ok"></SourceScreen>
    <list-tabs
      v-model="filterForm.tab"
      :isLoading="loading"
      :tabs="tabArr"
      @tab-click="handleChangeTab"
    >
    </list-tabs>
    <div class="list-content" v-loading="loading">
      <el-button
        size="small"
        type="danger"
        v-if="filterForm.tab == 'normal'"
        class="list-btn"
        @click="handleDeleteSource"
      >
        删除</el-button
      >
      <div class="list-btn" v-if="filterForm.tab == 'deleted'">
        <el-button size="small" type="success" @click="handleReduction"
          >还原</el-button
        >
        <el-button
          size="small"
          type="danger"
          style="margin-left: 18px"
          @click="handleDeleteSource"
          >删除
        </el-button>
      </div>
      <el-table
        style="width: 100%"
        class="thead-light"
        stripe
        :data="source_list"
        @selection-change="handleSelectionChange"
        @sort-change="sortChange"
      >
        <el-table-column type="selection" min-width="55"> </el-table-column>
        <el-table-column prop="id" label="ID" min-width="100"></el-table-column>
        <el-table-column label="排序" min-width="100">
          <template slot-scope="scope">
            <ListInputPopover
              v-model="scope.row.sort"
              :integer="true"
              @change="updateIsShow(scope.row.sort, scope.row.id, scope.$index)"
            >
            </ListInputPopover>
          </template>
        </el-table-column>
        <el-table-column label="图标" min-width="100">
          <template slot-scope="scope">
            <list-image :src="scope.row.icon || ''"></list-image>
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          label="名称"
          min-width="150"
        ></el-table-column>
        <el-table-column
          prop="articles_count"
          label="数量"
          min-width="100"
          sortable
        ></el-table-column>
        <el-table-column
          prop="create_time"
          label="创建时间"
          sortable
          min-width="150"
        ></el-table-column>
        <el-table-column label="显示" min-width="100">
          <template slot-scope="scope">
            <el-checkbox
              v-model="scope.row.status"
              :true-label="1"
              :false-label="0"
              @change="handleChangeStatus(scope.row)"
            ></el-checkbox>
          </template>
        </el-table-column>
        <el-table-column label="操作" min-width="100" fixed="right">
          <template slot-scope="scope">
            <el-button
              style="color: #3576ff"
              size="small"
              type="text"
              v-if="filterForm.tab == 'normal'"
              @click="handleShowAdd(scope.row.id)"
              >编辑</el-button
            >
            <el-button
              style="color: #3576ff"
              size="small"
              type="text"
              v-if="filterForm.tab == 'deleted'"
              @click="handleSelectionChange(scope.row.id, 'putBack')"
              >还原</el-button
            >
            <el-button
              style="color: #3576ff"
              size="small"
              type="text"
              @click="handleSelectionChange(scope.row.id, 'del')"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <Pagination
      :total="pageData.total"
      :page="pageData.current_page"
      :change="changPage"
    ></Pagination>
    <AddSource
      :showAddSource="showAddSource"
      :editId="editId"
      @closeAddSource="closeAddSource"
      @updateSourceList="updateSourceList"
    ></AddSource>
  </div>
</template>

<script>
import {
  sourceList,
  softDeleteSource,
  updateStatus,
  putBackSource,
  delSource,
  updateSort,
} from "../api/source-list";
import AddSource from "../components/SourceManage/AddSource";
import SourceScreen from "../components/SourceManage/SourceScreen";
import ListInputPopover from "@/base/components/List/ListInputPopover.vue";
import Pagination from "@/base/components/Default/Pagination";
import ListImage from "../../../base/components/List/ListImage";

export default {
  name: "source-manage",
  data() {
    return {
      tabArr: [
        {
          name: "normal",
          label: "全部",
        },
        {
          name: "deleted",
          label: "回收站",
        },
      ],
      filterForm: {
        tab: "normal",
        start_time: -1, //开始时间
        end_time: -1, //结束时间
        page_size: 15,
        keyword: "",
      },
      filters: {
        normal: null,
        deleted: null,
      },
      pageData: {},
      source_list: [],
      //删除的数组
      selectId: [],
      //显示新增弹窗
      showAddSource: false,
      loading: false,
      //编辑id
      editId: 0,
    };
  },
  components: {
    AddSource,
    SourceScreen,
    Pagination,
    ListImage,
    ListInputPopover,
  },
  mounted() {
    this.getSourceList(this.filterForm);
  },
  watch: {
    "filterForm.create_end_time"() {
      console.log(this.filterForm);
    },
  },
  methods: {
    sortChange(e) {
      this.filterForm.order_by = e.prop;
      if (e.order == "ascending") {
        this.filterForm.is_desc = 0;
      } else if (e.order == "descending") {
        this.filterForm.is_desc = 1;
      }
      this.getSourceList(this.filterForm);
    },
    changPage(e) {
      this.filterForm.page_size = e.page_size;
      const data = { ...this.filterForm, ...e };
      this.getSourceList(data);
    },
    // 筛选回调
    ok(e) {
      // const pageData = {};
      this.filterForm = { ...e, page: 1 };
      this.getSourceList(this.filterForm);
    },
    closeAddSource() {
      this.showAddSource = false;
      this.editId = 0;
    },
    //显示新增
    handleShowAdd(id) {
      this.editId = id;
      this.showAddSource = true;
    },
    //刷新列表
    updateSourceList() {
      this.editId = 0;
      this.getSourceList({
        ...this.filterForm,
        page: this.pageData.current_page,
      });
    },
    //还原
    handleReduction() {
      putBackSource({ id: this.selectId })
        .then((res) => {
          this.updateSourceList();
        })
        .catch(() => {});
    },
    //删除，还原项
    handleSelectionChange(e, type) {
      let arr = [];
      if (typeof e === "number" && type === "del") {
        arr.push(e);
        this.selectId = arr;
        this.handleDeleteSource();
      } else if (typeof e === "number" && type === "putBack") {
        arr.push(e);
        this.selectId = arr;
        this.handleReduction();
      } else {
        e.forEach((item, inde) => {
          arr.push(item.id);
        });
        this.selectId = arr;
      }
    },
    updateIsShow(e, id, i) {
      this.loading = true;
      updateSort({ id: id, sort: Number(e) })
        .then((res) => {
          this.$message({
            type: "success",
            message: "更新成功",
          });
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
    //软删除方法
    handleDeleteSource() {
      if (this.selectId.length == 0) {
        this.$message({
          message: "请选择你要删除的来源",
          type: "warning",
        });
        return;
      }
      let title = "";
      if (this.filterForm.tab === "normal") {
        title = "此操作将删除该来源, 是否继续?";
      } else {
        title = "此操作将永久删除该来源, 是否继续?";
      }
      this.$confirm(title, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.loading = true;
          if (this.filterForm.tab === "normal") {
            softDeleteSource({ id: this.selectId })
              .then((res) => {
                this.updateSourceList();
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
              })
              .catch(() => {
                this.loading = false;
              });
            // .finally(() => {
            //   this.loading = false;
            // });
          } else if (this.filterForm.tab === "deleted") {
            delSource({ id: this.selectId })
              .then((res) => {
                this.updateSourceList();
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
              })
              // .finally(() => {
              //   this.loading = false;
              // })
              .catch(() => {
                this.loading = false;
              });
          }
        })
        .catch(() => {});
    },
    //修改tab栏
    handleChangeTab(e) {
      if (!this.filters[e.name]) {
        this.filterForm = {
          tab: e.name,
          start_time: -1, //开始时间
          end_time: -1, //结束时间
          page_size: this.pageData.per_page,
          keyword: "",
        };
      } else {
        this.filterForm = this.filters[e.name];
      }
      this.getSourceList(this.filterForm);
    },

    //获取列表数据
    getSourceList(requestData) {
      this.loading = true;
      this.source_list = [];
      this.pageData = {};
      sourceList(requestData)
        .then((res) => {
          this.source_list = res.data.data;
          this.pageData = res.data;
          this.filters[res.data.tab] = {
            ...this.filterForm,
            page_size: res.data.per_page,
          };
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    //修改显示状态
    handleChangeStatus(item) {
      let data = {
        id: item.id,
      };
      if (item.status == "0") {
        data.status = 0;
      } else {
        data.status = 1;
      }
      this.loading = true;
      updateStatus(data)
        .then((res) => {
          this.$message({
            message: "状态修改成功",
            type: "success",
          });
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<template>
  <div class="list-image" :style="{height: myHeight, width: myWidth, borderRadius: myBorderRadius}">
    <el-image
      v-if="src"
      class="cursor-pointer"
      :src="fixImageUrl(src, {h: height})" :fit="fit"
      @click="preview? $previewImage([src]) : ()=>{}">
      <span slot="error">{{'' | placeholder}}</span>
    </el-image>
    <span v-else class="error">{{'' | placeholder}}</span>
  </div>
</template>

<script>
  export default {
    name: "ListImage",
    props: {
      src: {
        type: String,
        required: true
      },
      fit: {
        type: String,
        default: 'contain'
      },
      // 大图
      isImage: {
        type: Boolean,
        default: false
      },
      width: {
        type: Number
      },
      height: {
        type: Number
      },
      borderRadius: [Number, String],
      preview: {
        type: Boolean,
        default: true
      }
    },
    computed: {
      myHeight(){
        return this.height ? `${this.height}px` : (this.isImage ? '60px' : this.myWidth)
      },
      myWidth() {
        return this.width ? `${this.width}px` : (this.isImage ? 'auto' : '30px')
      },
      myBorderRadius() {
        return typeof this.borderRadius === 'string' ? this.borderRadius : (this.borderRadius + 'px')
      }
    }
  }
</script>

<style lang="scss" scoped>
  .list-image {
    width: auto;
    overflow: hidden;
    display: flex;
    align-items: center;

    .el-image {
      height: 100%;
      width: 100%;
    }
  }
</style>

<template>
  <el-dialog title="添加来源" :visible="showAddSource" width="504px" class="dialog-vertical" @close="closeAdd">
    <el-form ref="classifyForm" :model="classifyForm" label-width="80px" :rules="rules" size="medium" @submit.native.prevent>
      <el-form-item label="名称" prop="name">
        <el-input v-model="classifyForm.name" placeholder="输入名称"></el-input>
      </el-form-item>
      <el-form-item label="图标" prop="icon">
        <SingleMediaWall v-model="classifyForm.icon" :width="100" :height="100" borderRadius="6">
        </SingleMediaWall>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button size="small" @click="closeAdd">取消</el-button>
      <el-button size="small" type="primary" @click="handleAddClassify" :loading="saveLoading">确定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { addSource, sourceDetail } from "../../api/source-list";
import SingleMediaWall from "../../../common/components/SingleMediaWall";
export default {
  name: "AddSource",
  props: ["showAddSource", "editId"],
  data() {
    return {
      classifyForm: {
        name: "",
        icon: "",
      },
      rules: {
        name: [{ required: true, message: "请输入分类名称", trigger: "blur" }],
        icon: [{ required: true, message: "请上传图标", trigger: "blur" }],
      },
      saveLoading: false,
    };
  },
  components: { SingleMediaWall },
  watch: {
    editId() {
      if (this.editId > 0) {
        this.getEditData();
      }
    },
  },
  methods: {
    getEditData() {
      sourceDetail({ id: this.editId })
        .then((res) => {
          this.classifyForm = res.data;
        })
        .catch(() => {});
    },
    closeAdd() {
      this.classifyForm = {
        name: "",
        icon: "",
      };
      this.$emit("closeAddSource");
    },
    handleAddClassify() {
      this.$refs["classifyForm"].validate((valid) => {
        if (valid) {
          let data = {
            name: this.classifyForm.name,
            icon: this.classifyForm.icon,
          };
          if (this.editId > 0) {
            data.id = this.editId;
          } else {
            data.id = 0;
          }
          this.saveLoading = true;
          addSource(data)
            .then((res) => {
              if (this.editId > 0) {
                this.$message({
                  message: "修改成功",
                  type: "success",
                });
              } else {
                this.$message({
                  message: "新增成功",
                  type: "success",
                });
              }
              this.closeAdd();
              this.$emit("updateSourceList", data.name);
              this.saveLoading = false;
            })
            .catch(() => {
              this.saveLoading = false;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
</style>
